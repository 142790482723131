import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import PublicLayout from '../layouts/PublicLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import { useAuthStore } from '../stores/auth'
import { auth } from '../firebase/init'

// import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
  {
    path: '/',
    component: AppLayout,
    redirect: { name: 'home' },
    children: [
      {
        name: 'home',
        path: 'home',
        component: () => import('../pages/user/UserHome.vue'),
        meta: { requiresAuth: true },
      },
      // {
      //   name: 'report',
      //   path: 'r/:id?',
      //   component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      //   meta: { requiresAuth: false },
      // },
      // {
      //   name: 'compressed-report',
      //   path: 'r/c/:id?',
      //   component: () => import('../pages/admin/dashboard/DashboardCompressed.vue'),
      //   meta: { requiresAuth: false },
      // },
      // {
      //   name: 'campaign-report',
      //   path: 'campaign/:id?',
      //   component: () => import('../pages/admin/campaign-report/CampaignReport.vue'),
      // },
      {
        path: 'campaign-report/:id?',
        name: 'ReportDetail',
        component: () => import('../pages/admin/campaign-report/CampaignReportDetail.vue'),
        props: true,
      },
    ],
  },
  {
    component: PublicLayout,
    path: '/r',
    children: [
      {
        name: 'tiktok-report',
        path: 'tiktok/:id',
        component: () => import('../pages/admin/dashboard/InfluencerTiktokReport.vue'),
      },
      {
        name: 'youtube-report',
        path: 'youtube/:id',
        component: () => import('../pages/admin/dashboard/InfluencerYoutubeReport.vue'),
      },
      {
        name: 'report',
        path: ':id',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
    ],
  },
  {
    name: 'app',
    path: '/app',
    component: AppLayout,
    redirect: { name: 'home' },
    children: [],
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    redirect: { name: 'home' },
    children: [
      {
        name: 'admin-home',
        path: 'home',
        component: () => import('../pages/admin/AdminHome.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      {
        name: 'campaign-reports-browser',
        path: 'campaignreports-browser',
        component: () => import('../pages/admin/campaign-reports-browser/CampaignReportsBrowser.vue'),
      },
      {
        name: 'master-panel',
        path: 'master-panel',
        component: () => import('../pages/admin/master-admin-panel/MasterAdminPanel.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      {
        name: 'campaign-report-builder',
        path: 'campaign-builder/:id?',
        component: () => import('../pages/admin/campaign-report/CampaignReportBuilder.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      {
        name: 'data-entry',
        path: 'data-entry',
        component: () => import('../pages/admin/campaign-data-entry/AddSourceData.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      {
        name: 'jotform-generator',
        path: 'jotform-generator',
        component: () => import('../pages/admin/jotform-generator/JotformGenerator.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      {
        name: 'utility-page',
        path: 'utility-page',
        component: () => import('../pages/admin/utility-page/UtilityPage.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      {
        name: 'analysis-link-generator',
        path: 'analysis-link-generator',
        component: () => import('../pages/admin/analysis-link-generator/AnalysisLinkGenerator.vue'),
        meta: { requiresAuth: true, requiresRole: 'admin' },
      },
      // {
      //   name: 'settings',
      //   path: 'settings',
      //   component: () => import('../pages/settings/Settings.vue'),
      // },
      // {
      //   name: 'preferences',
      //   path: 'preferences',
      //   component: () => import('../pages/preferences/Preferences.vue'),
      // },
      // {
      //   name: 'users',
      //   path: 'users',
      //   component: () => import('../pages/users/UsersPage.vue'),
      // },
      // {
      //   name: 'projects',
      //   path: 'projects',
      //   component: () => import('../pages/projects/ProjectsPage.vue'),
      // },
      // {
      //   name: 'payments',
      //   path: '/payments',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'payment-methods',
      //       path: 'payment-methods',
      //       component: () => import('../pages/payments/PaymentsPage.vue'),
      //     },
      //     {
      //       name: 'billing',
      //       path: 'billing',
      //       component: () => import('../pages/billing/BillingPage.vue'),
      //     },
      //     {
      //       name: 'pricing-plans',
      //       path: 'pricing-plans',
      //       component: () => import('../pages/pricing-plans/PricingPlans.vue'),
      //     },
      //   ],
      // },
      // {
      //   name: 'faq',
      //   path: '/faq',
      //   component: () => import('../pages/faq/FaqPage.vue'),
      // },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      // {
      //   name: 'recover-password',
      //   path: 'recover-password',
      //   component: () => import('../pages/auth/RecoverPassword.vue'),
      // },
      // {
      //   name: 'recover-password-email',
      //   path: 'recover-password-email',
      //   component: () => import('../pages/auth/CheckTheEmail.vue'),
      // },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/user',
    component: AppLayout,
    children: [
      {
        name: 'user-home',
        path: 'home',
        component: () => import('../pages/user/UserHome.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'account-settings',
        path: 'settings',
        component: () => import('../pages/user/AccountSettings.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresRole = to.meta.requiresRole

  if (!authStore.isInitialized) {
    await authStore.initializeAuth()
  }

  console.log('IS USER AUTHENTICATED:', authStore.isAuthenticated)

  if (requiresAuth && !authStore.isAuthenticated) {
    console.log('requiresAuth and not authenticated')
    next({ name: 'signup', query: { redirect: to.fullPath } }) // Redirect to login if not authenticated
  } else if (requiresAuth && authStore.isAuthenticated) {
    console.log('current user:', auth.currentUser)
    await authStore.fetchUser(auth.currentUser)
    console.log('user:', authStore.user)
    console.log('auth role', authStore.getUserRole)
    const userRole = authStore.getUserRole

    if (requiresRole && userRole !== requiresRole) {
      next({ name: '404' }) // Redirect if user does not have the required role
    } else {
      next() // Proceed to route
    }
  } else {
    next() // Proceed to route
  }
})

export default router
