export default {
  presets: {
    light: {
      backgroundPrimary: '#F6F6F6',
      backgroundSecondary: '#FFFFFF',
      backgroundCardPrimary: '#F7F9F9',
      backgroundCardSecondary: '#ECFDE6',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
      primary: '#0f172a',
      darkpurple: '#2e1065',
    },
    dark: {
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#0f172a',
    },
  },
}
