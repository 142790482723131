import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCNsnHFCBtXEYhTzlxIVOJic8ZYTyZJArw',
  authDomain: 'popile-influencer.firebaseapp.com',
  projectId: 'popile-influencer',
  storageBucket: 'popile-influencer.appspot.com',
  messagingSenderId: '74545440679',
  appId: '1:74545440679:web:ee77e7f2480b7022647882',
}

const app = initializeApp(firebaseConfig)
const functions = getFunctions(app, 'europe-west1')
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

if (location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export { functions, app, db, auth, storage }
