export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'Home',
  },
  routes: [
    {
      name: 'brief',
      displayName: 'Brief Forms',
      meta: {
        icon: 'note_alt',
      },
      children: [
        {
          name: 'jotform-generator',
          displayName: 'Create Brief Form',
          meta: {
            icon: 'format_align_left',
          },
        },
      ],
    },
    {
      name: 'reports',
      displayName: 'Reports',
      meta: {
        icon: 'summarize',
      },
      children: [
        {
          name: 'campaign-report-builder',
          displayName: 'Report Builder',
          meta: {
            icon: 'build',
          },
        },
        {
          name: 'campaign-reports-browser',
          displayName: 'Browse Reports',
          meta: {
            icon: 'folder',
          },
        },
        {
          name: 'data-entry',
          displayName: 'Data Entry',
          meta: {
            icon: 'input',
          },
        },
        {
          name: 'master-panel',
          displayName: 'Users',
          meta: {
            icon: 'groups',
          },
        },
      ],
    },
    {
      name: 'analysis',
      displayName: 'Analysis',
      meta: {
        icon: 'contacts',
      },
      children: [
        {
          name: 'analysis-link-generator',
          displayName: 'Create Analysis',
          meta: {
            icon: 'link',
          },
        },
      ],
    },
    {
      name: 'other',
      displayName: 'Other',
      meta: {
        icon: 'terminal',
      },
      children: [
        {
          name: 'utility-page',
          displayName: 'Utility Page',
          meta: {
            icon: 'build_circle',
          },
        },
      ],
    },
    {
      name: 'auth',
      displayName: 'Authentication',
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'login',
          displayName: 'Login',
          meta: {
            icon: 'login',
          },
        },
        {
          name: 'signup',
          displayName: 'Sign Up',
          meta: {
            icon: 'person_add',
          },
        },
        {
          name: 'account-settings',
          displayName: 'Davet Et',
          meta: {
            icon: 'person_add',
          },
        },
        // {
        //   name: 'recover-password',
        //   displayName: 'Recover Password',
        //   meta: {
        //     icon: 'lock_open',
        //   },
        // },
        // {
        //   name: 'recover-password-email',
        //   displayName: 'Recover Password Email',
        //   meta: {
        //     icon: 'email',
        //   },
        // },
      ],
    },
  ] as INavigationRoute[],
}

export default navigationRoutes
