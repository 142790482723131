<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <RouterLink
        class="left-side h-full flex items-center justify-center"
        style="width: 35vw"
        to="/"
        aria-label="Visit homepage"
      >
        <div class="flex flex-col items-center justify-center h-full pt-10 pb-10 mx-2">
          <img :src="image" class="w-1/2 min-w-32 mb-4" alt="Popile" />
          <p class="text-center text-sm text-white w-1/2 min-w-32">
            Influencer pazarlamasında verinin ve yaratıcılığın birleşme noktası.
          </p>
          <span> </span>
        </div>
      </RouterLink>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <RouterLink class="py-4" to="/" aria-label="Visit homepage">
              <img :src="imageDark" width="100" alt="Popile" />
            </RouterLink>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
import image from '/assets/popile-logo-white.png'
import imageDark from '/popile-logo.png'
// import VuesticLogo from '../components/VuesticLogo.vue'

const breakpoint = useBreakpoint()
</script>

<style scoped>
.left-side {
  background-color: #190530;
}
</style>
