<template>
  <div class="app-navbar-actions">
    <!-- <GithubButton v-if="!isMobile" class="app-navbar-actions__item" /> -->
    <!-- <RouterLink to="/user/settings" class="app-navbar-actions__item">
      <VaButton
        v-if="!isMobile"
        preset="secondary"
        color="textPrimary"
        class="app-navbar-actions__item flex-shrink-0 mx-0"
      >
        Davet et</VaButton
      ></RouterLink
    > -->
    <RouterLink to="/home" class="app-navbar-actions__item">
      <VaButton
        v-if="!isMobile"
        preset="secondary"
        color="textPrimary"
        class="app-navbar-actions__item flex-shrink-0 mx-0"
      >
        Anasayfa</VaButton
      ></RouterLink
    >
    <!-- <NotificationDropdown class="app-navbar-actions__item" /> -->
    <!-- <div class="app-navbar-actions__item user-info">
      <span>{{ user?.displayName || user?.email }}</span>
      <VaButton preset="secondary" color="textPrimary" class="ml-2" @click="logout"> Logout </VaButton>
    </div> -->
    <ProfileDropdown class="app-navbar-actions__item app-navbar-actions__item--profile mr-1" />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { onAuthStateChanged, User } from 'firebase/auth'
// import { useI18n } from 'vue-i18n'
import { auth } from '../../../firebase/init' // Your Firebase init file

import ProfileDropdown from './dropdowns/ProfileDropdown.vue'
// import NotificationDropdown from './dropdowns/NotificationDropdown.vue'
// import GithubButton from './GitHubButton.vue'

// const { t } = useI18n()
const user = ref<User | null>(null)

onMounted(() => {
  user.value = auth.currentUser
  onAuthStateChanged(auth, (currentUser) => {
    user.value = currentUser
  })
})

// const logout = async () => {
//   await signOut(auth)
//   user.value = null
// }

defineProps({
  isMobile: { type: Boolean, default: false },
})
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .user-info {
    display: flex;
    align-items: center;

    span {
      margin-right: 0.5rem;
    }

    .va-button {
      padding: 0.25rem 0.5rem;
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
</style>
