// stores/auth.ts
import { defineStore } from 'pinia'
import { auth, db } from '../firebase/init'
import { doc, setDoc, getDoc } from 'firebase/firestore'

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  User,
  isSignInWithEmailLink,
  onAuthStateChanged,
} from 'firebase/auth'

import { sendSignInLinkViaCloudFunction } from '../services/api'

interface AuthState {
  user: User | null
  userRole: string | null
  initialized: boolean // Added initialized state
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    userRole: null,
    initialized: false, // Initial state
  }),
  actions: {
    async fetchUser(user: User | null) {
      this.user = user
      if (user) {
        console.log('fetching user role', user)
        const idTokenResult = await user.getIdTokenResult()
        console.log('idTokenResult', idTokenResult)
        return (this.userRole = idTokenResult.claims.admin ? 'admin' : 'user') // Default role
      } else {
        return (this.userRole = null)
      }
    },
    async signIn(email: string, password: string) {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      this.fetchUser(userCredential.user)
    },
    async signUp(email: string, password: string) {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      this.fetchUser(userCredential.user)
    },
    async sendSignInLink(email: string, actionCodeSettings: any) {
      const response = await sendSignInLinkViaCloudFunction(email, actionCodeSettings)
      console.log('Cloud Function Response:', response)
      localStorage.setItem('emailForSignIn', email)
    },
    async signInWithEmailLink(email: string, emailLink: string) {
      const userCredential = await signInWithEmailLink(auth, email, emailLink)
      await this.fetchUser(userCredential.user)
      if (this.user) {
        const userDocRef = doc(db, 'users', this.user.uid)
        const userDoc = await getDoc(userDocRef)
        console.log('userDoc', userDoc)
        if (!userDoc.exists()) {
          // Create new user in Firestore
          await setDoc(userDocRef, {
            email: this.user.email,
            createdAt: new Date(),
            accessTokens: [],
          })
        }
      }
      localStorage.removeItem('emailForSignIn')
    },
    async logOut() {
      await signOut(auth)
      this.fetchUser(null)
      window.location.reload()
    },
    isSignInWithEmailLink(emailLink: string): boolean {
      return isSignInWithEmailLink(auth, emailLink)
    },
    async completeSignInWithEmailLink(emailLink: string) {
      if (this.isSignInWithEmailLink(emailLink)) {
        let email = localStorage.getItem('emailForSignIn')
        if (!email) {
          // If email is not in localStorage, prompt user to provide it
          email = window.prompt('Lütfen teyit için e-posta adresini tekrar gir')
        }
        if (email) {
          await this.signInWithEmailLink(email, emailLink)
        }
      }
    },
    initializeAuth() {
      return new Promise<void>((resolve) => {
        onAuthStateChanged(auth, (user) => {
          this.fetchUser(user).then(() => {
            this.initialized = true
            resolve()
          })
        })
      })
    },
  },
  getters: {
    isAuthenticated: (state): boolean => !!state.user,
    getUserRole: (state): string | null => state.userRole,
    isInitialized: (state): boolean => state.initialized,
  },
})
