// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'
import clarityPlugin from './services/clarity'
import LuckyOrange from './services/luckyorange'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import { useAuthStore } from './stores/auth'
import { auth } from './firebase/init'
import './overrides.css'
const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.use(clarityPlugin)
app.use(LuckyOrange)
app.use(createVuestic({ config: vuesticGlobalConfig }))

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')

const authStore = useAuthStore()

auth.onAuthStateChanged(async (user) => {
  await authStore.fetchUser(user)
  if (!app._container) {
    app.mount('#app')
  }
})

const emailLink = window.location.href

if (authStore.isSignInWithEmailLink(emailLink)) {
  let email = window.localStorage.getItem('emailForSignIn')
  if (!email) {
    email = window.prompt('Lütfen teyit için e-posta adresini tekrar gir')
  }
  if (email) {
    authStore
      .signInWithEmailLink(email, emailLink)
      .then(() => {
        window.localStorage.removeItem('emailForSignIn')
        router.push({ name: 'user-home' }) // Redirect to the dashboard or any other route
      })
      .catch((error) => {
        console.error('Error signing in with email link:', error)
      })
  }
}
